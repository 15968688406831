'use client';
import React, { useState } from 'react';
import ForUsers from './ForUsers';
import ForAuthors from './ForAuthors';
import ForPublishers from './ForPublishers';

const AudienceInformation = () => {
  const [activeSection, setActiveSection] = useState('users');

  const sections = {
    users: <ForUsers />,
    authors: <ForAuthors />,
    publishers: <ForPublishers />,
  };

  return (
    <div className="h-full flex flex-col pt-20 bg-white" id="learnmore">
      {/* Header Section */}
      <div className="text-center px-4">
        <div className="flex justify-center">
          <img
            alt="Company Logo"
            src="/SymphonyPages_icon_1.jpg"
            className="h-32 w-auto sm:h-40"
          />
        </div>
        <h2 className="text-base sm:text-lg font-semibold leading-8 text-indigo-600">SymphonyPages</h2>
        <p className="mt-2 text-2xl sm:text-4xl font-bold tracking-tight text-gray-900">
          Transform your reading into a symphony
        </p>
        <p className="mt-4 sm:mt-6 text-sm sm:text-lg leading-6 sm:leading-8 text-gray-600">
          Enhance books with personalized soundtracks that adapt to your reading style and mood.
        </p>
      </div>

      {/* Selection Section */}
      <div className="flex flex-col lg:flex-row w-11/12 max-w-6xl mx-auto mt-8 lg:h-72 h-screen lg:space-x-2 space-y-4 lg:space-y-0 lg:px-4">
        {/* User Card */}
        <div
          className={`flex-1 cursor-pointer bg-cover object-cover bg-center rounded-lg relative ${
            activeSection === 'users' ? 'ring-4 ring-indigo-400' : ''
          }`}
          onClick={() => setActiveSection('users')}
        >
          <img
            alt="For Users"
            src="/forusers.jpeg"
            className="h-full w-full absolute rounded-lg opacity-20 hover:opacity-50"
          />
          <p className="w-full h-full text-center flex items-center justify-center absolute font-bold text-xl sm:text-4xl text-gray-900">
            User
          </p>
        </div>

        {/* Author Card */}
        <div
          className={`flex-1 cursor-pointer bg-cover object-cover bg-center rounded-lg relative ${
            activeSection === 'authors' ? 'ring-4 ring-indigo-400' : ''
          }`}
          onClick={() => setActiveSection('authors')}
        >
          <img
            alt="For Authors"
            src="/forauthors.jpg"
            className="h-full w-full absolute rounded-lg opacity-20 hover:opacity-50"
          />
          <p className="w-full h-full text-center flex items-center justify-center absolute font-bold text-xl sm:text-4xl text-gray-900">
            Author
          </p>
        </div>

        {/* Publisher Card */}
        <div
          className={`flex-1 cursor-pointer bg-cover object-cover bg-center rounded-lg relative ${
            activeSection === 'publishers' ? 'ring-4 ring-indigo-400' : ''
          }`}
          onClick={() => setActiveSection('publishers')}
        >
          <img
            alt="For Publishers"
            src="/forpublishers.jpg"
            className="h-full w-full absolute rounded-lg opacity-20 hover:opacity-50"
          />
          <p className="w-full h-full text-center flex items-center justify-center absolute font-bold text-xl sm:text-4xl text-gray-900">
            Publisher
          </p>
        </div>
      </div>

      {/* Content Section */}
      <div className="w-full bg-white p-4 sm:p-6 mt-6">
        {sections[activeSection]}
      </div>
    </div>
  );
};

export default AudienceInformation;
