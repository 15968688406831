import React from 'react';
import { BookOpenIcon, MusicalNoteIcon, SparklesIcon } from '@heroicons/react/20/solid';

const features = [
  {
    name: 'Personalized Soundtracks',
    description: 'Add a music soundtrack that aligns with the mood of the story, tailored to your taste.',
    icon: MusicalNoteIcon,
  },
  {
    name: 'Dynamic Progression',
    description:
      'Soundtracks adjust to your reading speed, ensuring a seamless experience throughout the story.',
    icon: SparklesIcon,
  },
  {
    name: 'Easy Integration',
    description:
      'Requires only a Spotify Premium subscription to enjoy a complete immersive reading experience.',
    icon: BookOpenIcon,
  },
];

const ForUsers = () => {
  return (
    <div className="bg-white" id="forusers">
      <div className="mx-auto w-full ">

        <div className='flex-col flex lg:flex-row items-center justify-center'>

            {/* Features Section */}
            <div className="grid p-6 w-full flex-col lg:w-1/3">
            <div className='bg-indigo-100 p-4 rounded-lg bg-opacity-50'>
                <h3 className="text-2xl font-semibold tracking-tight text-gray-900">How It Works</h3>
                <p className="mt-4 text-base leading-7 text-gray-600">
                SymphonyPages analyzes the mood of the text and matches it with a fitting music soundtrack
                tailored to your taste. The soundtrack evolves with the story to complement its emotions and
                keeps in sync with your unique reading speed.
                </p>
            </div>
            <div className='bg-green-100 p-4 rounded-lg bg-opacity-50 mt-4'>
                <h3 className="text-2xl  font-semibold tracking-tight text-gray-900">Requirements</h3>
                <p className="mt-4 text-base leading-7 text-gray-600">
                All you need is a Spotify Premium subscription (any plan: Individual, Family, Duo, or Student)
                to access SymphonyPages' immersive music experience.
                </p>
            </div>
            </div>

            {/* Icon-Based Features */}
            <div className="grid flex-col w-full lg:w-1/3 p-6 justify-center">
            {features.map((feature) => (
                <div key={feature.name} className="relative flex items-center justify-center mt-6">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600 text-white mr-2">
                    <feature.icon className="h-20 w-20" aria-hidden="true" />
                </div>
                <div>
                <p className="ml-12 text-lg font-semibold leading-8 text-gray-900">{feature.name}</p>
                <p className=" ml-12 text-base leading-7 text-gray-600">{feature.description}</p>
                </div>
                </div>
            ))}
            </div>

            {/* Coming Soon Section */}
            <div className="text-center w-full lg:w-1/3 p-6 flex justify-center">
            <div className='bg-yellow-100 rounded-lg bg-opacity-50 p-4 lg:w-2/3'>
            <h3 className="text-2xl font-semibold tracking-tight text-gray-900">Exciting Features Coming Soon</h3>
            <ul className="mt-8 space-y-2 text-base leading-7 text-gray-600">
                <li>Character Profiles</li>
                <li>Character Map</li>
                <li>Physical Book Support</li>
                <li>Upload of Your Personal Library</li>
                <li>Author-Selected Soundtracks</li>
                <li>Offline Access</li>
                <li>Multi-Device Synchronization</li>
                <li>Music Selection for Books from All Over the World</li>
            </ul>

            </div>
            </div>


        </div>


      </div>
    </div>
  );
};

export default ForUsers;
