import React from 'react';
import { GlobeAltIcon, CurrencyDollarIcon, ChartBarIcon } from '@heroicons/react/20/solid';

const features = [
  {
    name: 'Audience Engagement',
    description:
      'SymphonyPages helps publishers reach new, tech-savvy audiences by providing an immersive reading experience.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Monetization',
    description:
      'Open up a new revenue stream by offering an enhanced reading experience with soundtracks.',
    icon: CurrencyDollarIcon,
  },
  {
    name: 'Data Insights',
    description:
      'Gain insights into user interaction with your titles, helping you make informed decisions.',
    icon: ChartBarIcon,
  },
];

const ForPublishers = () => {
  return (
    <div className="overflow-hidden bg-white" id="forpublishers">
      <div className="mx-auto w-full">
        <div className="flex-col flex lg:flex-row  items-center justify-center">
          {/* Features Section */}
          <div className="grid p-6 w-full flex-col lg:w-1/3">
            <div className="bg-indigo-100 p-4 rounded-lg bg-opacity-50">
              <h3 className="text-2xl font-semibold tracking-tight text-gray-900">How It Works</h3>
              <p className="mt-4 text-base leading-7 text-gray-600">
                SymphonyPages adds music soundtracks to books based on the mood and tone of the content,
                enhancing the reader’s experience. Using advanced sentiment and ambient analysis, it selects
                music that fits each scene’s emotions.
              </p>
            </div>
            <div className="bg-green-100 p-4 rounded-lg bg-opacity-50 mt-4">
              <h3 className="text-2xl font-semibold tracking-tight text-gray-900">Book Licenses</h3>
              <p className="mt-4 text-base leading-7 text-gray-600">
                Our partnership with Buchzentrum Schweiz AG ensures book licenses are covered, enabling publishers
                to bring their catalog into SymphonyPages smoothly and securely.
              </p>
            </div>
          </div>

          {/* Icon-Based Features */}
          <div className="grid max-w-xl flex-col w-full lg:w-1/3 p-6 justify-center">
            {features.map((feature) => (
              <div key={feature.name} className="relative flex items-center justify-center mt-6">
                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600 text-white mr-2">
                  <feature.icon className="h-12 w-12" aria-hidden="true" />
                </div>
                <div>
                  <p className="ml-12 text-lg font-semibold leading-8 text-gray-900">{feature.name}</p>
                  <p className="ml-12 text-base leading-7 text-gray-600">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForPublishers;
