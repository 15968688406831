import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
        question: 'Music Customization for Focused Reading',
        answer: 'For those who find music distracting while reading, SymphonyPages offers the option to choose instrumental, voice-less soundtracks. This allows you to enjoy an immersive experience without vocal distractions, keeping the focus on the book while still enhancing the atmosphere with background music.',
    },
    {
        question: 'How does SymphonyPages select the music?',
        answer: 'It uses sentiment and ambient analysis to determine the mood of the text and match it with appropriate music, based on your personal taste.',
    },
    {
        question: 'What if I don’t want music while reading?',
        answer: 'You can easily toggle the music on or off anytime.',
    },
    {
        question: 'Do I need to pay to use SymphonyPages?',
        answer: 'SymphonyPages has a pricing model designed to be affordable. With more users, our goal is to make it nearly free for everyone.',
    },
    // More questions...
]

export default function FAQS() {
    return (
        <div className="py-20 lg:py-40 bg-gradient-to-r from-blue-50 to-indigo-100 h-screen" id="faqs">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                    {/* Left Text Block */}
                    <div className="lg:col-span-6 lg:pr-8">
                        <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl  text-gray-900 leading-tight">
                            Your Books,
                        </h2>
                        <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl  text-gray-900 pt-4 lg:pt-8 leading-tight">
                            your Music,
                        </h2>
                        <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 pt-4 lg:pt-8 leading-tight">
                            your Moment.
                        </h2>
                    </div>
                    {/* FAQ Section */}
                    <div className="mt-10 lg:mt-0 lg:col-span-6">
                        <dl className="space-y-6 divide-y divide-gray-300">
                            {faqs.map((faq) => (
                                <Disclosure key={faq.question} as="div" className="pt-6 bg-white rounded-lg shadow-md">
                                    <dt className="p-4">
                                        <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                            <span className="text-lg font-semibold leading-7 group-hover:text-indigo-600 transition-colors duration-200">
                                                {faq.question}
                                            </span>
                                            <span className="ml-6 flex h-7 items-center">
                                                <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden transition-transform duration-200" />
                                                <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden transition-transform duration-200" />
                                            </span>
                                        </DisclosureButton>
                                    </dt>
                                    <DisclosurePanel as="dd" className="p-4 text-gray-600">
                                        <p className="text-base leading-7">{faq.answer}</p>
                                    </DisclosurePanel>
                                </Disclosure>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    )
}
