import React, { useState, Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './base_elements/Layout';
import AuthWrapper from './base_elements/AuthWrapper';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import apiClient from './apiClient';
import { toast } from 'react-toastify';
import { AuthProvider } from './base_elements/AuthContext';

// Frontage imports
import FrontpageBase from './frontpage/FrontpageBase';

// Lazy loading components for code splitting
const LoginComponent = lazy(() => import('./user_pages/login'));
const UserSettings = lazy(() => import('./user_pages/UserSettings'));
const SpotifyUser = lazy(() => import('./user_pages/SpotifyUser'));
const BookSelection = lazy(() => import('./pages/BookSelection'));
const BookManagementComponent = lazy(() => import('./pages/BookManagement'));
const CategorizeBook = lazy(() => import('./pages/CategorizeBook'));
const BookDetail = lazy(() => import('./pages/BookDetail'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const Playlist = lazy(() => import('./pages/Playlist'));
const UserReadingList = lazy(() => import('./pages/UserReadingList'));
const UserReadList = lazy(() => import('./pages/UserReadList'));
const UserToReadList = lazy(() => import('./pages/UserToReadList'));
const BugReports = lazy(() => import('./pages/BugReports'));
const UserManagement = lazy(() => import('./user_pages/UserManagement'));
const UserData = lazy(() => import('./user_pages/UserData'));
const AIPromptTest = lazy(() => import('./pages/AIPromptTest'));


// Fallback component to show while lazy loaded components are being fetched
const Loading = () => <div>Loading...</div>;

// Error boundary component for lazy-loaded components
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error loading component:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>Something went wrong while loading this component.</div>;
    }
    return this.props.children; 
  }
}

function App() {
  const local_url = "http://localhost:8000"
  const prod_url = "https://symphonypages-backend.azurewebsites.net"
  const api_url = prod_url
  const [currentPage, setCurrentPage] = useState('Discover');
  const [books, setBooks] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [readingList, setReadingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csrfToken, setCsrfToken] = useState(null)
  const [selectedLanguage, setSelectedLanguage] = useState('all'); // Default to 'all'






function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie string begins with the name we want
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

  useEffect(() => {
    setCsrfToken(getCookie('csrftoken'))

    console.log("cookie", getCookie('csrftoken'))
  }, []);  // Run once on load

  const fetchReadingList = async () => {
    try {
      const response = await apiClient.get(`${api_url}/api/reading-list/my-list`);
      setReadingList(response.data.map(item => item.id));
    } catch (error) {
      console.error('Error fetching reading list', error);
      toast.error('Error fetching reading list');
    }
  };



  const removeFromReadingList = async (bookId) => {
    try {
      setLoading(true);
      
      // Make the API call using apiClient
      const response = await apiClient.post(`${api_url}/api/reading-list/remove/`, 
      {
        book: bookId
      }, 
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,  // Include the CSRF token
        },
      });
  
      fetchReadingList();
      fetchBooks();
  
      if (!response.status === 200) {
        throw new Error('Network response was not ok');
      }
  
      toast.success('Book removed from reading list');
    } catch (error) {
      console.error('Error removing book from list:', error);
      toast.error('Error removing book from list');
    } finally {
      setLoading(false);
    }
  };

  const fetchBooks = async (reset = false) => {
    try {
      setLoading(true);
    
      // Construct the URL with query parameters
      const currentPage = reset ? 1 : page;
      const tagsParam = selectedTags.length > 0 ? `&genre_tags__name=${selectedTags.join(',')}` : '';
      const languageParam = selectedLanguage !== 'all' ? `&language=${selectedLanguage}` : ''; // Language filter
      const statusParam = '&status=Published'; // Only fetch published books
      const url = `${api_url}/api/books/?page=${currentPage}${tagsParam}${languageParam}${statusParam}`;
    
      // Fetch books with apiClient
      const response = await apiClient.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,  // Include the CSRF token
        },
      });
    
      // Check for response status
      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }
    
      const data = response.data;
    
      // Update books state and pagination
      if (reset) {
        setBooks(data.results);
        setHasMore(data.next !== null);
        setPage(2); // Set to next page after initial fetch
      } else {
        setBooks((prevBooks) => [...prevBooks, ...data.results]);
        setHasMore(data.next !== null);
        setPage((prevPage) => prevPage + 1); // Increment page only after appending new data
      }
    } catch (error) {
      console.error('Error fetching books:', error);
      toast.error('Error fetching books');
    } finally {
      setLoading(false);
    }
  };

  const fetchTags = async () => {
    try {
      // Fetch tags with apiClient
      const response = await apiClient.get(`${api_url}/api/genre-tags/`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,  // Include the CSRF token
        },
      });
  
      // Check for response status
      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }
  
      const data = response.data;
      setTags(data.results);
    } catch (error) {
      console.error('Error fetching tags:', error);
      toast.error('Error fetching tags');
    }
  };

  const fetchMoreBooks = () => {
    fetchBooks();
  };


  const addToReadingList = async (bookId) => {
    try {
      // Set loading state to true while the request is being processed
      setLoading(true);
  
      // Make the API request to add the book to the reading list
      const response = await apiClient.post(`${api_url}/api/reading-list/`, 
        { book: bookId },  // Send the book ID in the request body
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,  // Include the CSRF token
          },
        }
      );
  
      // Check if the response is not OK
      if (response.status !== 200 && response.status !== 201) {
        console.error('Network response was not ok', response.statusText); // Debugging
        throw new Error('Network response was not ok');
      }
  
      // Parse the response data
      const data = response.data;
      console.log('Book added to reading list:', data); // Debugging
  
      // Update the reading list state with the new book ID
      setReadingList((prev) => [...prev, bookId]);
  
      // Show success message
      toast.success('Book added to reading list');
    } catch (error) {
      // Log and show error message
      console.error('Error adding book to list:', error); // Debugging
      toast.error('Error adding book to list');
    } finally {
      // Set loading state to false after the request is complete
      setLoading(false);
    }
  };

  // Update document title based on the current page
  useEffect(() => {
    document.title = `${currentPage} - SymphonyPages`;
  }, [currentPage]);

  return (
    <AuthProvider api_url={api_url}>
    <Router>
      <Suspense fallback={<Loading />}>
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<FrontpageBase 
            api_url={api_url}
            csrfToken={csrfToken}
            />} />
            {/* Route for the login page */}
            <Route path="/login" element={<LoginComponent 
            api_url={api_url}
            csrfToken={csrfToken}
            />} />
            
            {/* All other routes are wrapped in the AuthWrapper */}
            <Route
              path="/reader/*"
              element={
                <AuthWrapper 
                api_url={api_url}
                csrfToken={csrfToken}
                >
                  <Layout currentPage={currentPage} setCurrentPage={setCurrentPage} 
                  api_url={api_url}
                  csrfToken={csrfToken}
                  tags={tags}
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                  fetchBooks={fetchBooks}
                  books={books}
                  hasMore={hasMore}
                  fetchMoreBooks={fetchMoreBooks}
                  readingList={readingList}
                  addToReadingList={addToReadingList}
                  removeFromReadingList={removeFromReadingList}
                  fetchReadingList={fetchReadingList}
                  fetchTags={fetchTags}
                  setPage={setPage}
                  >
                  <Routes>
                    {/* Route for book selection page */}
                    <Route path="/" element={<BookSelection  
                    api_url={api_url}
                    tags={tags}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    fetchBooks={fetchBooks}
                    books={books}
                    hasMore={hasMore}
                    fetchMoreBooks={fetchMoreBooks}
                    readingList={readingList}
                    addToReadingList={addToReadingList}
                    removeFromReadingList={removeFromReadingList}
                    fetchReadingList={fetchReadingList}
                    fetchTags={fetchTags}
                    setPage={setPage}
                    csrfToken={csrfToken}
                    setBooks={setBooks}
                    page={page}
                    setHasMore={setHasMore}
                    setSelectedLanguage={setSelectedLanguage}
                    selectedLanguage={selectedLanguage}
                    />} />
                    
                    {/* Route for profile page */}
                    <Route path="/profile" element={<ProfilePage 
                    api_url={api_url}/>} />

                    {/* Route for profile page */}
                    <Route path="/usermanagement" element={<UserManagement 
                    api_url={api_url} csrfToken={csrfToken}/>} />
                    
                    {/* Route for profile page */}
                    <Route path="/userdata" element={<UserData 
                    api_url={api_url} csrfToken={csrfToken}/>} />

                    {/* Route for book upload page */}
                    <Route path="/book-management" element={<BookManagementComponent 
                    api_url={api_url}
                    books={books}
                    fetchBooks={fetchBooks}
                    setBooks={setBooks}
                    tags={tags}
                    fetchTags={fetchTags}/>}
                    csrfToken={csrfToken} />

                    <Route path="/spotifyuser" element={<SpotifyUser 
                    api_url={api_url}
                    books={books}
                    fetchBooks={fetchBooks}
                    setBooks={setBooks}
                    tags={tags}
                    fetchTags={fetchTags}/>}
                    csrfToken={csrfToken} />
                    
                    {/* Route for reading list page */}
                    <Route path="/toreadlist" element={<UserToReadList 
                    api_url={api_url}
                    tags={tags}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    fetchBooks={fetchBooks}
                    books={books}
                    hasMore={hasMore}
                    fetchMoreBooks={fetchMoreBooks}
                    readingList={readingList}
                    addToReadingList={addToReadingList}
                    removeFromReadingList={removeFromReadingList}
                    fetchReadingList={fetchReadingList}
                    fetchTags={fetchTags}
                    setPage={setPage}
                    csrfToken={csrfToken}
                    />} />
                    
                    {/* Route for reading list page */}
                    <Route path="/readinglist" element={<UserReadingList 
                    api_url={api_url}
                    tags={tags}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    fetchBooks={fetchBooks}
                    books={books}
                    hasMore={hasMore}
                    fetchMoreBooks={fetchMoreBooks}
                    readingList={readingList}
                    addToReadingList={addToReadingList}
                    removeFromReadingList={removeFromReadingList}
                    fetchReadingList={fetchReadingList}
                    fetchTags={fetchTags}
                    setPage={setPage}
                    csrfToken={csrfToken}
                    />} />
                    
                    {/* Route for reading list page */}
                    <Route path="/readlist" element={<UserReadList 
                    api_url={api_url}
                    tags={tags}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    fetchBooks={fetchBooks}
                    books={books}
                    hasMore={hasMore}
                    fetchMoreBooks={fetchMoreBooks}
                    readingList={readingList}
                    addToReadingList={addToReadingList}
                    removeFromReadingList={removeFromReadingList}
                    fetchReadingList={fetchReadingList}
                    fetchTags={fetchTags}
                    setPage={setPage}
                    csrfToken={csrfToken}
                    />} />
                    
                    
                    
                    {/* Route for user settings page */}
                    <Route path="/settings" element={<UserSettings
                    api_url={api_url}
                    csrfToken={csrfToken} />} />
                    
                    {/* Route for categorizing a book */}
                    <Route path="/categorize/:id" element={<CategorizeBook
                    api_url={api_url}
                    csrfToken={csrfToken} />} />
                    
                    {/* Route for book detail page */}
                    <Route path="/book-detail/:id" element={<BookDetail 
                    api_url={api_url}
                    addToReadingList={addToReadingList}
                    removeFromReadingList={removeFromReadingList}
                    readingList={readingList}
                    setReadingList={setReadingList}
                    fetchReadingList={fetchReadingList}
                    csrfToken={csrfToken}
                    />} />

                    {/* Route for Bug Reports */}
                    <Route path="/bugreports" element={<BugReports
                    api_url={api_url}
                    csrfToken={csrfToken} />} />

                    {/* Route for Bug Reports */}
                    <Route path="/aiprompttest" element={<AIPromptTest
                    api_url={api_url}
                    csrfToken={csrfToken} />} />
                    
                    
                    {/* Route for playlist page */}
                    <Route path="/playlist/:id" element={<Playlist
                    api_url={api_url} />}
                    csrfToken={csrfToken} />
                    </Routes>
                  </Layout>
                </AuthWrapper>
              }
            />
          </Routes>
        </ErrorBoundary>
      </Suspense>
    </Router>

    </AuthProvider>
  );
}

export default App;
