import { useContext, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthWrapper from '../base_elements/AuthWrapper';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

// Utility function to handle class name concatenation
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ProfileDropdown = () => {
  const [username, setUsername] = ""
  const navigate = useNavigate();

  // User navigation options based on authentication status
  const userNavigation = [
    { name: 'Settings', href: '/reader/settings' },
    { name: 'Sign out', onClick: () => logout() },
  ];

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('spotify_access_token');
    window.location.reload()
  };


  return (
    <Menu as="div" className="relative">
  
        {/* Menu button */}
        <Menu.Button className="-m-1.5 flex items-center p-1.5">
        <span className="sr-only">Open user menu</span>
        
        {/* User avatar (only visible when logged in) 
            <img
            alt=""
            src=""
            className="h-8 w-8 rounded-full bg-gray-50"
            /> */}
        {/* User name and dropdown icon (visible on larger screens) */}
        <span className="hidden lg:flex lg:items-center">
            <span aria-hidden="true" className="ml-4 text-sm font-semibold leading-6 text-gray-900">
            {username}
            </span>
            <ChevronDownIcon aria-hidden="true" className="ml-2 h-5 w-5 text-gray-400" />
        </span>
        </Menu.Button>

        {/* Transition effect for the dropdown menu */}
        <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        >
        
        {/* Menu items */}
        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
            
            {/* Menu items for logged-in users */}
            {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                {({ active }) => (
                    <Link
                    to={item.href}
                    onClick={item.onClick}
                    className={`block px-3 py-1 text-sm leading-6 text-gray-900 ${active ? 'bg-gray-50' : ''}`}
                    >
                    {item.name}
                    </Link>
                )}
                </Menu.Item>
            ))}
        </Menu.Items>
        </Transition>
    </Menu>
  );
};

export default ProfileDropdown;
