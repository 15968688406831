import React from 'react';
import { BookOpenIcon, MusicalNoteIcon, SparklesIcon } from '@heroicons/react/20/solid';

const features = [
  {
    name: 'How SymphonyPages Works',
    description:
      'SymphonyPages transforms your book into a soundtrack-accompanied experience without changing any text. Your words remain as you wrote them, while the music complements the atmosphere of each scene.',
    icon: MusicalNoteIcon,
  },
  {
    name: 'Future Opportunities',
    description:
      'Authors will soon have the ability to select their own music choices for their books and set pricing options for readers, offering creative freedom and direct connection with audiences.',
    icon: BookOpenIcon,
  },
];

const ForAuthors = () => {
  return (
    <div className="bg-white" id="forauthors">
      <div className="mx-auto w-full">
        <div className="flex-col flex lg:flex-row  items-center justify-center">
          {/* Features Section */}
          <div className="grid p-6 w-full lg:max-w-2xl flex-col w-1/3">
            <div className="bg-indigo-100 p-4 rounded-lg bg-opacity-50">
              <h3 className="text-2xl font-semibold tracking-tight text-gray-900">How It Works</h3>
              <p className="mt-4 text-base leading-7 text-gray-600">
                SymphonyPages transforms your book into a soundtrack-accompanied experience without changing any
                text. Your words remain as you wrote them, while the music complements the atmosphere of each scene.
              </p>
            </div>
            <div className="bg-green-100 p-4 rounded-lg bg-opacity-50 mt-4">
              <h3 className="text-2xl font-semibold tracking-tight text-gray-900">
                Honoring the Author’s Work
              </h3>
              <p className="mt-4 text-base leading-7 text-gray-600">
                SymphonyPages respects and celebrates the unique work of each author, preserving your words exactly as
                they are while enhancing the experience for readers.
              </p>
            </div>
          </div>

          {/* Coming Soon Section */}
          <div className="text-center w-full lg:w-1/3 p-6 flex justify-center">
            <div className="bg-yellow-100 rounded-lg bg-opacity-50 p-4 lg:w-2/3">
              <h3 className="text-2xl font-semibold tracking-tight text-gray-900">Coming Soon</h3>
              <ul className="mt-8 space-y-4 text-base leading-7 text-gray-600">
                <li>Select music for your books</li>
                <li>Set pricing options for readers</li>
                <li>Engage directly with your audience</li>
                <li>Creative control over the reader’s experience</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForAuthors;
