import React, { useState } from 'react';

export default function Footer() {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const togglePrivacyPolicy = () => {
    setShowPrivacyPolicy((prev) => !prev);
  };

  return (
    <>
      {/* Banner */}
      <div className="bg-gray-800 text-white py-3 flex items-center justify-center">
        <img src='/swissflag.png' className='h-12 w-20'></img>
        <span className="text-sm font-medium mr-2">100% developed and operated in Switzerland</span>
      </div>

      <footer className="bg-gray-900 text-white py-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 flex justify-between">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Company Info */}
            <div>
              <h3 className="text-lg font-semibold">SymphonyPages</h3>
              <p className="mt-4 text-sm text-gray-400">
                Dive deeper into your favorite books – with personalized music that enhances your reading experience.
              </p>
            </div>
          </div>
            {/* Navigation Links */}
            <div>
              <h3 className="text-lg font-semibold">Navigation</h3>
              <ul className="mt-4 space-y-2">
                <li><a href="/" className="text-gray-400 hover:text-white">Home</a></li>
                <li><a href="#learnmore" className="text-gray-400 hover:text-white">Learn More</a></li>
                <li><a href="#faqs" className="text-gray-400 hover:text-white">FAQs</a></li>
                <li><a href="#contact" className="text-gray-400 hover:text-white">Contact</a></li>
              </ul>
            </div>
        </div>
          <div className="mt-8 border-t border-gray-700 pt-8 text-sm text-gray-400 text-center md:flex md:items-center md:justify-between p-8">
            <p>&copy; 2024 SymphonyPages. All rights reserved.</p>
            <div className="mt-4 md:mt-0">
              <button
                onClick={togglePrivacyPolicy}
                className="text-gray-400 hover:text-white underline focus:outline-none"
              >
                Privacy Policy
              </button>
            </div>
          </div>
      </footer>

      {/* Privacy Policy Modal */}
      {showPrivacyPolicy && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full mx-4">
            <div className="flex justify-between items-center border-b pb-4">
              <h2 className="text-2xl font-semibold text-gray-900">Privacy Policy</h2>
              <button
                onClick={togglePrivacyPolicy}
                className="text-gray-500 hover:text-gray-900 focus:outline-none"
              >
                ✕
              </button>
            </div>
            <div className="mt-4 text-gray-700">
              <p>
                Your privacy is important to us. At SymphonyPages, we ensure that your data is handled with the utmost
                care. We do not share your information with third parties without consent. For detailed information,
                please review our complete Privacy Policy document.
              </p>
              {/* Add full privacy policy details here */}
            </div>
            <div className="mt-6 flex justify-end">
              <button
                onClick={togglePrivacyPolicy}
                className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 focus:outline-none"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
