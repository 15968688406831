'use client'

import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import { UsersIcon, Cog6ToothIcon, Bars3Icon, BookOpenIcon, BookmarkIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { MagnifyingGlassIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import ProfileDropdown from '../components/ProfileDropdown';
import axios from 'axios';
import apiClient from '../apiClient';

import BookSelection from '../pages/BookSelection';

const navigation = [
  { name: 'Discover', href: '/reader', icon: UsersIcon },
  // Add other navigation items as needed
];

const user_books = [
  { id: 1, name: 'Reading', href: '/reader/readinglist', icon: BookOpenIcon },
  { id: 2, name: 'Want to Read', href: '/reader/toreadlist', icon: BookmarkIcon },
  { id: 3, name: 'Read', href: '/reader/readlist', icon: CheckCircleIcon },
  // Add other items as needed
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Layout({ children, currentPage, setCurrentPage, api_url, csrfToken, tags, selectedTags, setSelectedTags, fetchBooks, books, hasMore, fetchMoreBooks, readingList, addToReadingList, removeFromReadingList, fetchReadingList, fetchTags, setPage }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isBugReportVisible, setBugReportVisible] = useState(false);
  const [bugDescription, setBugDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSuperuser, setIsSuperuser] = useState(false);  // Track if the user is a superuser

  // Function to get the value of a specific cookie by name
  useEffect(() => {
    const logData = async () => {
        const response = apiClient.post(api_url + "/api/log-data/", 
          { data: currentPage }
        );

    };

    if (currentPage) {
      logData();
    }
  }, [currentPage]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiClient.get(`${api_url}/api/user/`);
        
        // Assuming response.data contains user info and has the is_superuser field
        setIsSuperuser(response.data.is_superuser);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();  // Fetch user data when the component mounts
  }, [api_url]);
  const toggleBugReport = () => {
    setBugReportVisible(!isBugReportVisible);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiClient.get(`${api_url}/api/user/`);
  
        if (response.status !== 200) {
          throw new Error(`Error fetching user data: ${response.statusText}`);
        }
  
        const data = response.data;
        setUsername(data.username);
        console.log('User data fetched successfully:', data);
  
      } catch (error) {
        console.error('Error fetching user data:', error);
        navigate('/login');  // Redirect to login if there's an error
      }
    };
  
    fetchUserData();
  }, []);

  const handleSubmit = async () => {
    if (!bugDescription.trim()) {
      alert('Please describe the issue.');
      return;
    }
  
    try {
      setLoading(true);
  
      const response = await apiClient.post(`${api_url}/api/bugs/`, {
        description: bugDescription,  // Only send the description and status
        status: 'Open',  // Default status for new bugs
      });
    
      const data = response.data;
      console.log('Bug report submitted successfully:', data);
  
      // Reset the form and close the overlay
      setBugDescription('');
      toggleBugReport();
      alert('Bug report submitted successfully!');
  
    } catch (error) {
      console.error('Error submitting bug report:', error);
      alert('Failed to submit bug report.');
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch search results
  const fetchSearchResults = async (query) => {
    if (query.trim() === '') {
      setSearchResults([]);
      return;
    }

    try {
      const response = await apiClient.get(`${api_url}/api/books/search`, {
        params: { query },
      });

      if (response.status !== 200) {
        throw new Error(`Error searching books: ${response.statusText}`);
      }

      const data = response.data;
      setSearchResults(data.results);

    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  // Handle input change and search query
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    fetchSearchResults(query);
  };
  return (
    <>
      <div className="flex h-screen">
        {/* Sidebar for larger screens */}
        <div className={`fixed inset-y-0 z-50 flex w-72 flex-col transition-transform transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0`}>
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center font-bold">
                <img
                    alt="Company Logo"
                    src="/SymphonyPages_icon_1.jpg"
                    className="h-12 w-auto"
                    onClick={() => navigate("/")}
                />
                SymphonyPages
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          onClick={() => setCurrentPage(item.name)}
                          className={classNames(
                            currentPage === item.name
                              ? 'bg-gray-50 text-indigo-600'
                              : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 cursor-pointer'
                          )}
                        >
                          <item.icon
                            aria-hidden="true"
                            className={classNames(
                              currentPage === item.name ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                              'h-6 w-6 shrink-0'
                            )}
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Your Books</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {user_books.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        onClick={() => setCurrentPage(item.name)}
                        className={classNames(
                          currentPage === item.name
                            ? 'bg-gray-50 text-indigo-600'
                            : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                          'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 cursor-pointer'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            'h-6 w-6 shrink-0',
                            currentPage === item.name ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600'
                          )}
                          aria-hidden="true"
                        />
                        <span className="truncate">{item.name}</span>
                      </Link>
                    </li>
                  ))}
                  </ul>
                </li>
                {isSuperuser && (
                  <li className="mt-auto">
                    <Link
                      to="/reader/spotifyuser"
                      onClick={() => setCurrentPage('Spotify User')}
                      className={classNames(
                        currentPage === 'User Management'
                          ? 'bg-gray-50 text-indigo-600'
                          : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 cursor-pointer'
                      )}
                    >
                      <Cog6ToothIcon
                        aria-hidden="true"
                        className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                      />
                    Spotify User
                    </Link>
                    <Link
                      to="/reader/usermanagement"
                      onClick={() => setCurrentPage('User Management')}
                      className={classNames(
                        currentPage === 'User Management'
                          ? 'bg-gray-50 text-indigo-600'
                          : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 cursor-pointer'
                      )}
                    >
                      <Cog6ToothIcon
                        aria-hidden="true"
                        className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                      />
                      User Management
                    </Link>
                    <Link
                      to="/reader/bugreports"
                      onClick={() => setCurrentPage('Bug Reports')}
                      className={classNames(
                        currentPage === 'Bug Reports'
                          ? 'bg-gray-50 text-indigo-600'
                          : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 cursor-pointer'
                      )}
                    >
                      <Cog6ToothIcon
                        aria-hidden="true"
                        className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                      />
                      Bug Reports
                    </Link>
                    <Link
                      to="/reader/book-management"
                      onClick={() => setCurrentPage('Upload Book')}
                      className={classNames(
                        currentPage === 'Upload Book'
                          ? 'bg-gray-50 text-indigo-600'
                          : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                        'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 cursor-pointer'
                      )}
                    >
                      <Cog6ToothIcon
                        aria-hidden="true"
                        className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                      />
                      Book Management
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>

        {/* Overlay for mobile sidebar */}
        {sidebarOpen && (
          <div className="fixed inset-0 z-40 bg-black opacity-50 lg:hidden" onClick={() => setSidebarOpen(false)}></div>
        )}

        {/* Main content area */}


        <div className="flex flex-col flex-1 lg:pl-72">


              <div className="sticky top-0 z-40 mx-auto max-w-full px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="flex h-12 mt-4 items-center gap-x-2 sm:gap-x-4 lg:gap-x-6 border-b border-gray-200 bg-white shadow-sm lg:shadow-none">
          <button
            type="button"
            onClick={() => setSidebarOpen(true)}
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          >
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
          <div aria-hidden="true" className="h-6 w-px bg-gray-200 lg:hidden" />
          <div className="flex flex-1 items-center gap-x-2 sm:gap-x-4 lg:gap-x-6">
            <form action="#" method="GET" className="relative flex flex-1">
              <label htmlFor="search-field" className="sr-only">Search</label>
              <MagnifyingGlassIcon aria-hidden="true" className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400" />
              <input
                id="search-field"
                name="search"
                type="search"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="block w-full py-1 pl-8 pr-1 text-gray-900 placeholder:text-gray-400 focus:outline-blue-200 sm:text-sm lg:w-96"
              />
            </form>
            <div className="flex items-center gap-x-2 sm:gap-x-4 lg:gap-x-6">
              <div aria-hidden="true" className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" />
              <div className="flex items-center justify-center">
                <p className="font-bold text-gray-700 mr-2 text-sm">{username}</p>
                <ProfileDropdown />
              </div>
              {/* Report Bug Button 
              <button
                onClick={toggleBugReport}
                className="flex items-center px-3 py-1 bg-red-600 text-white rounded-md hover:bg-red-700 transition-all"
              >
                <ExclamationTriangleIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                Report a Bug
              </button>*/}
            </div>
          </div>
        </div>

        {/* Bug Report Overlay */}
        {isBugReportVisible && (
          <div className="absolute top-14 left-0 right-0 mx-auto w-full max-w-md sm:max-w-xl md:max-w-2xl lg:max-w-4xl bg-white border border-gray-200 shadow-lg p-4 sm:p-6 mt-2">
            <h2 className="text-lg font-semibold">Report a Bug</h2>
            <p className="text-sm text-gray-500 mt-1">
              Please include the following information to help us investigate the issue:
            </p>
            <ul className="list-disc list-inside text-sm text-gray-500 mt-1">
              <li>Which page did it happen on?</li>
              <li>What was the issue?</li>
              <li>What were you doing when it happened?</li>
              <li>Any other details that might be relevant.</li>
            </ul>
            <textarea
              rows="5"
              className="mt-4 block w-full p-3 border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
              placeholder="Describe the issue here..."
              value={bugDescription}
              onChange={(e) => setBugDescription(e.target.value)}
            ></textarea>
            <div className="flex justify-end mt-4">
              <button
                onClick={toggleBugReport}
                className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-all"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="ml-3 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-all"
                disabled={loading}
              >
                {loading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </div>
        )}
      </div>



        {/* Conditionally render either search results or main content */}
        <main className="flex-1 py-10">
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            {searchQuery ? (
              <div>
                <h2 className="text-xl font-semibold text-gray-800 mb-4">Search Results</h2>
                <BookSelection  
                  api_url={api_url}
                  tags={tags}
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                  fetchBooks={fetchBooks}
                  books={searchResults}
                  hasMore={hasMore}
                  fetchMoreBooks={fetchMoreBooks}
                  readingList={readingList}
                  addToReadingList={addToReadingList}
                  removeFromReadingList={removeFromReadingList}
                  fetchReadingList={fetchReadingList}
                  fetchTags={fetchTags}
                  setPage={setPage}
                  csrfToken={csrfToken}
                  />
              </div>
            ) : (
              <>
                <Outlet />
                {children}
              </>
            )}
          </div>
        </main>
      </div>
    </div>
    </>
  );
}
